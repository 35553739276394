import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Badge,
  Avatar,
} from "@mui/material";
import logo from "./Image/kowa.svg";
import {
  MissedSamplesIcon,
  MessagesIcon,
  RerunSampleIcon,
  CriticalLogsIcon,
} from "./icons/ButtonIcons";
import { useMsal } from "@azure/msal-react";
import MuiAppBar from "@mui/material/AppBar";
import { useEffect, useState } from "react";
import { useUser } from "./rootContext";
import { Link, useLocation } from "react-router-dom";
import AppDialog from "./components/AppDialog";
import LabDialog from "./components/LabDialog";
import AboutDialog from "./components/AboutDialog";
import UserMessagesDialog from "./components/UserMessagesDialog";
import useFetch from "./hooks/useFetch";
import { DETAILS_APIS } from "./common/apis";
import menu from "./header/menu";
import { WindowTypes, useWindowPopups } from "./components/useWindowPopups";
import ConfirmModal from "./components/ConfirmModal";
import TopNavbar from "./components/TopNavbar";

export default function AppTitleBar() {
  const { instance } = useMsal();
  const user = useUser();
  const { post } = useFetch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [lab, setLab] = useState("Lab");
  const [selectedLabID, setSelectedLabID] = useState();
  const [topNot, setTopNot] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [dialog, setDialog] = useState({
    open: false,
    fullWidth: null,
    width: "",
    title: "",
  });
  const [isTabActive, setIsTabActive] = useState(true);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLab = (lab) => {
    setLab(lab);
  };

  let location = useLocation();

  const getTopNots = async () => {
    if (isTabActive == true) {
      const topNotifications = await post(DETAILS_APIS.TOP_NOTIFICATIONS, {
        LaboratoryId: user.DefaultLaboratoryId,
        ExcludeToday: true,//making it false for missed and hold samples
        IncludeOnHold: false,
        showToday: true,//making it true for rerun samples
        showOutstandingOnly: false,
      }, { skipBlocker: true });
      setTopNot(topNotifications);
    }
  }

  const handleRefreshApp = () => {
    localStorage.clear();
    localStorage.setItem("v_key", 54);
    // window.location.reload(true);
    window.location.href = window.location.href;
  }

  const handleMouseClick = (event, path) => {
    if (event.button === 1) {
      window.open(path, '_blank')
    }
  }

  const HandleNewWindow = (actionName, data) => {
    useWindowPopups(actionName, data)
  }

  function rowActionHandler(row, actionName) {
    if (Object.values(WindowTypes).includes(actionName)) {
      HandleNewWindow(actionName, row)
      return;
    }
  }

  useEffect(() => {

    const v_key = JSON.parse(localStorage.getItem("v_key"));
    if (v_key !== null) {
      if (v_key != "54") {
        setDialog({
          open: true,
          title: "Refresh App",
          message: "Please close all tabs except one and hit Refresh.",
          width: "md",
        });
      }
    }
    else {
      localStorage.setItem("v_key", 54);
    }
    // if (v_key != "54") {
    //   setDialog({
    //     open: true,
    //     title: "Refresh App",
    //     message: "Please close all tabs except one and hit Refresh.",
    //     width: "md",
    //   });
    // }

    getTopNots();

    const handleVisibilityChange = () => {
      setIsTabActive(!document.hidden);
    };

    if ((user?.RoleAdministration || user?.RoleDeveloper) !== true) {
      let list = menu.filter((x) => x.name !== "administration");
      setMenuList(list);
    }
    if ((user?.RoleAdministration || user?.RoleDeveloper) === true) {
      setMenuList(menu);
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);

    setLab(user.DefaultLaboratory || "Lab");
    const interval = setInterval(() => {
      getTopNots()
    }, (10 * 60000));

    return () => {
      clearInterval(interval);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }

  }, []);

  useEffect(() => {
    if (location?.pathname !== "/Chart") {
      localStorage.setItem("isCloseWindow", true);
    }
  }, [location]);

  return (
    <>
      <MuiAppBar
        position="fixed"
        sx={{
          boxShadow: "1px 1px 0px 0px #0000001f",
          display: location.pathname !== "/Chart" ? "block" : "none",
        }}
      >
        <Toolbar style={{ minHeight: "50px" }}>
          <Link to="/">
            <Box
              component="img"
              sx={{
                height: 30,
                mr: 1,
              }}
              alt="KOWA"
              src={logo}
            />
          </Link>
          <Box
            sx={{
              flexGrow: 1,
              mt: "-3px",
              display: { /*xs: "none",*/ md: "flex" },
            }}
          >
            <TopNavbar menuList={menuList} />

          </Box>

          <Box
            sx={{
              marginLeft: "auto",
              display: "flex",
              mr: 0.5,
              mt: 1,
              cursor: "pointer",
            }}
          >
            <Badge
              badgeContent={topNot?.CriticalSamples}
              max={999}
              color="error"
              sx={{
                marginRight: "15px",
                [`.MuiBadge-badge`]: { right: "-3px" },
              }}
              className="top-badge"
              onMouseDown={(e) => handleMouseClick(e, '/critical-logs-v2')}

              onClick={() =>
                rowActionHandler({}, "critical-logs-v2")
              }
              title={"Critical Logs V2"}
            >
              <CriticalLogsIcon
                sx={{
                  fontSize: "25px!important",
                  width: "1rem",
                  height: "1rem",
                  color: "#FF0000", //"#0085FF",
                  paddingTop: "4px",
                }}
              />
            </Badge>
            <Badge
              badgeContent={topNot?.CriticalSamples}
              max={999}
              color="error"
              sx={{
                marginRight: "15px",
                [`.MuiBadge-badge`]: { right: "-3px" },
              }}
              className="top-badge"
              onMouseDown={(e) => handleMouseClick(e, '/reports/critical-logs')}

              onClick={() =>
                rowActionHandler({}, "critical-logs")
              }
              title={"Critical Logs"}
            >
              <CriticalLogsIcon
                sx={{
                  fontSize: "25px!important",
                  width: "1rem",
                  height: "1rem",
                  color: "#253FC8", //"#0085FF",
                  paddingTop: "4px",
                }}
              />
            </Badge>
            <Badge
              badgeContent={topNot?.OustandingRerun}
              max={999}
              color="error"
              sx={{
                marginRight: "15px",
                [`.MuiBadge-badge`]: { right: "-3px" },
              }}
              className="top-badge"
              onMouseDown={(e) => handleMouseClick(e, '/reports/rerun-samples')}
              onClick={() =>
                rowActionHandler({}, "rerun-samples")
              }
              title={"Rerun Samples"}
            >
              <RerunSampleIcon
                sx={{
                  fontSize: "25px!important",
                  width: "1.2rem",
                  height: "1.2rem",
                  color: "#253FC8", //"#0085FF",
                  paddingTop: "4px",
                }}
              />
            </Badge>
            <Badge
              badgeContent={topNot?.MissedSamples}
              max={999}
              color="error"
              sx={{
                marginRight: "15px",
                [`.MuiBadge-badge`]: { right: "-3px" },
              }}
              className="top-badge"
              onMouseDown={(e) => handleMouseClick(e, '/missed-samples')}
              onClick={() =>
                rowActionHandler({}, "missed-samples")
              }
              title={"Missed Samples"}
            >
              <MissedSamplesIcon
                sx={{
                  fontSize: "25px!important",
                  width: "1rem",
                  color: "#253FC8", //"#0085FF",
                }}
              />
            </Badge>
            <Badge
              badgeContent={topNot?.Messages}
              max={999}
              color="error"
              sx={{
                marginRight: "15px",
                [`.MuiBadge-badge`]: { right: "-3px" },
              }}
              onClick={() =>
                setDialog({
                  open: true,
                  title: "Messages",
                  width: "md",
                })
              }
              title={"Messages"}
            >
              <MessagesIcon
                sx={{
                  fontSize: "25px!important",
                  width: "1rem",
                  height: "1rem",
                  color: "#253FC8", //"#0085FF",
                  paddingTop: "4px",
                }}
              />
            </Badge>
            <Typography
              title="Selected Lab"
              variant="subtitle2"
              noWrap
              textOverflow={"hidden"}
              sx={{ color: "#003300", mx: 0.75 }}
              onClick={() =>
                setDialog({
                  open: true,
                  title: "Change Laboratory",
                  width: "md",
                })
              }
            >
              {lab || user?.DefaultLaboratory}
            </Typography>
            <Typography variant="subtitle2" noWrap sx={{ color: "#000" }}>
              | {user?.Fullname}
            </Typography>
          </Box>

          <IconButton onClick={handleMenu}>
            <Avatar
              sx={{
                fontSize: "12px !important",
                bgcolor: "#FFDB78",
                color: "#000",
              }}
            >
              {user?.Forename?.slice(0, 1).toUpperCase() +
                user?.Surname?.slice(0, 1).toUpperCase()}
            </Avatar>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() =>
                setDialog({
                  open: true,
                  title: "About",
                  width: "md",
                }) & setAnchorEl(null)
              }
            >
              About
            </MenuItem>
            <MenuItem
              onClick={() =>
                setDialog({
                  open: true,
                  title: "Refresh App",
                  message: "Please close all tabs except one and hit Refresh.",
                  width: "md",
                }) & setAnchorEl(null)
              }
            >
              Refresh App
            </MenuItem>
            <MenuItem onClick={() => { localStorage.clear(); instance.logoutRedirect(); }}>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </MuiAppBar>

      <AppDialog
        open={dialog.open}
        fullWidth={dialog.fullWidth}
        title={dialog.title}
        maxWidth={dialog.width}
        handleClose={() =>
          setDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
      >
        {dialog.title === "Change Laboratory" && (
          <LabDialog
            setSelectedLabID={setSelectedLabID}
            selectedLabID={selectedLabID}
            getLab={getLab}
            setDialog={setDialog}
          />
        )}
        {dialog.title === "About" && <AboutDialog />}
        {dialog.title === "Refresh App" && <ConfirmModal
          handleClose={() => {
            setDialog((prevState) => ({
              ...prevState,
              open: false,
            }));
          }}
          alertAction={handleRefreshApp}
          message={dialog.message}
          buttonText={"Refresh"}
          buttonText2={"Cancel"}
        />}
        {dialog.title === "Messages" && <UserMessagesDialog />}
      </AppDialog>
    </>
  );
}
