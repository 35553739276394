import { Card, Grid, Typography, } from "@mui/material";
import { useEffect, useMemo, useReducer, useState } from "react";
import { CRITICAL_LOGS_APIS, FLEET_APIS } from "../../../common/apis";
import { EventReducer, FormSections, SearchFilterDefaults, } from "./SampleReportsOverviewForm";
import SampleReportsOverviewFormTable from "./SampleReportsOverviewFormTable";
import useFetch from "../../../hooks/useFetch";
import Form from "../../../components/Form";
import { useWindowPopups } from "../../../components/useWindowPopups";

export default function SampleReportsOverview({
  formData,
  rowDataProps,
  setOpenDialog,
}) {

  const { post, postFile } = useFetch();
  const [data, dispatch] = useReducer(EventReducer, SearchFilterDefaults);
  const [sampleReportsData, setSampleReportsData] = useState([]);
  const [customer, setCustomer] = useState("")
  const [localData, setLocalData] = useState({})

  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName, { ...data });
  }

  function searchContactScope() {
    HandleNewWindow("search-contact",)
  }

  const handleExportContacts = async () => {
    await postFile(CRITICAL_LOGS_APIS.EXPORT_CONTACTS_FOR_SCOPE, {
      ContactId: formData?.ContactId || localData?.ContactId,
      ProductId: formData?.ProductId || localData?.ProductId,
      SiteSetId: formData?.SiteSetId || localData?.SiteSetId,
      ParentId: formData?.ParentId || localData?.ParentId,
      ReportNumber: formData?.ReportNumber || localData?.ReportNumber,

      SampleNumber: formData?.SampleNumber || localData?.SampleNumber,
      ComponentId: formData?.ComponentId || localData?.ComponentId,
      UnitId: formData?.UnitId || localData?.UnitId,
      SiteId: formData?.SiteId || localData?.SiteId,
      CustomerId: formData?.CustomerId || localData?.CustomerId,
    },
      "Export Contacts ");
  }

  function rowSelectedHandler(rowData) {
    rowDataProps && rowDataProps(rowData);
  }

  const filteredData = useMemo(() => {
    if (data) {
      return (sampleReportsData || []).filter((x) => {
        let filterMatch = true;
        if (data.Filter) {
          const filterLower = data.Filter.toLowerCase();
          filterMatch =
            x.DisplayName?.toLowerCase().includes(filterLower) ||
            x.Username?.toLowerCase().includes(filterLower) ||
            x.Company?.toLowerCase().includes(filterLower);
        }

        const alertCondition =
          !data.showAllAlerts && parseInt(data.AlertsFrom) <= x.Level;

        return (
          filterMatch &&
          ((filterMatch && data.Komatsu && x.IsKomatsu) ||
            (data.Other && !x.IsKomatsu)) &&
          ((data.Product && x.LevelText === "Product") ||
            (data.SiteSet && x.LevelText === "Site Set") ||
            (data.Customer && x.LevelText === "Customer") ||
            (data.Site && x.LevelText === "Site") ||
            (data.Unit && x.LevelText === "Unit") ||
            (data.Parent && x.LevelText === "Parent") ||
            (data.Unit && x.LevelText === "Unit") ||
            (data.Component && x.LevelText === "Component")) &&
          (data.showAllAlerts || alertCondition)
        );
      });
    } else {
      return sampleReportsData;
    }
  }, [data, sampleReportsData]);

  const eventCb = () => {

    const selectContact = JSON.parse(localStorage.getItem('search-contact-resp'));
    if (selectContact) {
      localStorage.removeItem('search-contact-resp')
    }
  }

  useEffect(function () {
    window.addEventListener("storage", eventCb);
    return function () {
      window.removeEventListener("storage", eventCb);
    };
  });

  useEffect(() => {

    (async function () {
      let parseData = JSON.parse(localStorage.getItem("report-contacts"));

      document.title = "Sample Reporting Overview - " + (parseData?.Customer || "");

      if (parseData !== null) {
        setLocalData(parseData);
        setCustomer(parseData?.Customer);
      }
      let resData = await post(FLEET_APIS.CONTACT_FOR_LINKAGE, {
        ContactId: formData?.ContactId || parseData?.ContactId,
        ProductId: formData?.ProductId || parseData?.ProductId,
        SiteSetId: formData?.SiteSetId || parseData?.SiteSetId,
        ParentId: formData?.ParentId || parseData?.ParentId,
        ReportNumber: formData?.ReportNumber || parseData?.ReportNumber,

        SampleNumber: formData?.SampleNumber || parseData?.SampleNumber,
        ComponentId: formData?.ComponentId || parseData?.ComponentId,
        UnitId: formData?.UnitId || parseData?.UnitId,
        SiteId: formData?.SiteId || parseData?.SiteId,
        CustomerId: formData?.CustomerId || parseData?.CustomerId,
      });
      setSampleReportsData(resData);

      if (parseData?.Customer?.length < 1 && resData?.length > 0) {
        document.title = "Sample Reporting Overview - " + (resData[0]?.Customer || "");
      }

      localStorage.removeItem("report-contacts");
    })();
  }, [formData]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={FormSections(searchContactScope, handleExportContacts)}
              data={data}
              dispatch={dispatch}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData && filteredData.length} </b> for customer {formData?.Customer || customer}
          </Typography>
          <SampleReportsOverviewFormTable
            setOpenDialog={setOpenDialog}
            data={filteredData}
            rowSelected={rowSelectedHandler}
          />
        </Grid>
      </Grid>
    </>
  );
}
