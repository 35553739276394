import {
  FormControlLabel,
  styled,
  Switch,
} from "@mui/material";

export default function SwitchInput({
  id,
  name,
  label,
  labelColor,
  value,
  errorMsg,
  ...options
}) {

  return (
    <FormControlLabel
      control={
        <SwitchStyled
          checked={value}
          name={name}
          id={id}
          size="small"
          {...options}
        />
      }
      label={label}
      sx={{
        mb: "5px",
        ml: 0,
        "& .MuiFormControlLabel-label": {
          color: labelColor || "",
          ml: "5px",
        },
        "& .MuiFormControlLabel-label.Mui-disabled": {
          color: "#000000",
          // Add any additional styles you want for the disabled label
        },
      }}
    />
  );
}

const SwitchStyled = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.primary.dark
            : theme.palette.primary.main,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
  "&.red": {
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.error.dark
              : theme.palette.error.main,
        },
      },
    },
  },
  "&.back-red": {
    "& .MuiSwitch-switchBase": {
      "& + .MuiSwitch-track": {
        backgroundColor: "red",
      },
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
  "&.green": {
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.success.dark
              : theme.palette.success.main,
        },
      },
    },
  },
  "&.orange": {
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.warning.dark
              : theme.palette.warning.main,
        },
      },
    },
  },
  "&.brown": {
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#964b00" : "#964b00",
        },
      },
    },
  },
  "&.darkslategray": {
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor: "darkslategray",
        },
      },
    },
  },
  "&.maroon": {
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "maroon" : "maroon",
        },
      },
    },
  },
}));
